import { useRouteLoaderData } from '@remix-run/react';
import type { MetaFunction } from '@remix-run/node';
import { PublicLayoutBase } from '@components/layouts/public-layout';
import {
  fullStructuredData,
  shortStructuredData,
} from '@components/layouts/meta';
import type { loader as rootLoader } from 'app/root';

export const meta: MetaFunction = () => [
  {
    'script:ld+json': fullStructuredData,
  },
  {
    'script:ld+json': shortStructuredData,
  },
];

export default function PublicWithBannerLayout() {
  const data = useRouteLoaderData<typeof rootLoader>('root');
  const isAuth = Boolean(data?.user?.id);

  return (
    <PublicLayoutBase
      isAuth={isAuth}
      headerProps={{
        id: data?.user?.id?.toString(),
        userEmail: data?.user?.email,
        userName: data?.user?.name,
        userType: data?.user?.userType,
      }}
      showBanner
    />
  );
}
